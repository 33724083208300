import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { State } from '../model/state.model';
import { ErrorCode } from '../model/errorcode';

@Injectable()
export class PlayerService {
    private interval: number = 0;
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public state: State = (new State()).init();
    private prevTimeSong: number = 0;
    public isPlay: boolean = false;
    public commands: Object[] = [];
    private MAX_COMMAND_SEND: number = 10;
    public playlistLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private errCounter: number = 0;
    public songChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public noResults: boolean = false;
    public deltaVolume: BehaviorSubject<number | null> = new BehaviorSubject(null);
    volumeChanged = new EventEmitter<{volume: number, force?: boolean}>();
    public platformPauseSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private appService: AppService,
        private router: Router,
        private alertController: AlertController,
    ) {
        if (this.authService.authenticated) {
            this.start();

            this.platformPauseSubject.subscribe((pauseValue: boolean) => {
                // on open app from background force to download all information in sync()
                if (pauseValue === false) {
                    this.commands.push({ id: 99 });
                }
            });
        }
    }

    public start(): void {
        this.errCounter = 0;
        const command: string = JSON.stringify({ "id": 99 });

        this.appService.showNotification("connecting...");
        this.apiService.sendCommand(this.authService.currentUser, command).subscribe(states => {
            states.forEach(state => { this.buildState(state); });
            this.ready.next(true);
            this.interval = window.setInterval(() => {
                this.sync().subscribe(states => {
                    states.forEach(state => { this.buildState(state); });
                    this.errCounter = 0;
                }, err => {
                    console.error(err);
                    this.appService.loading = "";
                    this.appService.showNotification("connection problem");
                    if (++this.errCounter > 20) {
                        this.stop();
                        this.authService.logout();
                        this.router.navigateByUrl("/auth/login");
                    }
                });
            },
                1000);
        },
            err => {
                this.appService.loading = "";
                if (this.authService.user && this.authService.user.children.length == 0) {
                    this.authService.logout();
                    this.router.navigateByUrl("/auth/login");
                }

            });
    }

    public stop(): void {
        clearInterval(this.interval);
        this.volumeChanged.emit({ volume: 0, force: true });
        this.state = (new State()).init();
    }

    private sync(): Observable<State[]> {
        try {
            // 0 - получение минимума информации: текущего времени песни и некого кэш-тайма
            // 99 - получение максимума информации
            // если state еще не получен - дергаем сервер 99-й командой, если уже получен - то 0-й        
            let command: string = JSON.stringify({ id: this.state.currentSong.name === "" ? 99 : 0 });

            if (this.state.currentSong.name === "") {
                this.appService.showNotification("connecting...");
            }

            // remove extra 99 comands
            let count: number = this.commands.filter(c => (<any>c).id === 99).length;
            if (count > 1) {
                this.commands = this.commands.filter(c => (<any>c).id !== 99);
                this.commands.push({ id: 99 });
            }

            if (this.commands.length > 0) {
                if (this.commands.length > this.MAX_COMMAND_SEND) {
                    const firstCommands = this.commands.slice(0, this.MAX_COMMAND_SEND);
                    this.commands = this.commands.slice(this.MAX_COMMAND_SEND);
                    command = JSON.stringify(firstCommands);
                }
                else {
                    command = JSON.stringify(this.commands);
                    this.commands = [];
                }
            }
            
            return this.apiService.sendCommand(this.authService.currentUser, command);
        } catch (err) { }
    }

    private async buildState(state: State): Promise<void> {
        try {
            if (state.errorCode) {
                let errorHeader = "Error";
                let errorMessage: string;
                switch (state.errorCode) {
                    case ErrorCode.AuthFailed:
                        errorMessage = state.error;
                        break;
                    case ErrorCode.NoPermissionForCommand:
                        errorHeader = null;
                        errorMessage = `No permission for command ${state.error}`;
                        break;
                    default:
                        errorMessage = `Unknown error ${state.error}`;
                        break;
                }
                const alert = await this.alertController.create({
                    header: errorHeader,
                    message: errorMessage,
                    buttons: ['OK'],
                  });
                await alert.present();
            }

            this.prevTimeSong = this.state.currentTimeSong;

            for (const field in state) {
                if (field === "playlistSongs") {
                    this.state[field] = this.state[field].concat(state[field]);
                } else {
                    this.state[field] = state[field];
                }
                if (field === "volume") {
                    this.volumeChanged.emit({ volume: state[field] });
                }
            }

            this.isPlay = this.state.currentTimeSong !== this.prevTimeSong;

            if (state.searchResultsList) { // switch off preloader when got search result         
                this.appService.loading = "";
                this.noResults = !state.searchResultsList.length;
            }

            if (state.playlistSongs && state.playlistSongs.length) { // switch off preloader when got playlist songs
                this.appService.loading = "";
                this.playlistLoaded.next(true);
            }

            // if currentSong changed then request full info to update current playlist
            if (state.currentSong && state.volume === undefined) {
                this.commands.push({ id: 99 });
                this.songChanged.next(true);
            }
        } catch (err) { }
    }

    public setVolume(v: number): void {
        this.appService.showNotification("sending [volume]");
        let i: number = this.commands.findIndex(c => (<any>c).id === 8);

        if (i === -1) {
            this.commands.push({ id: 8, volume: v });
            this.commands.push({ id: 99 });
        } else {
            this.commands[i] = { id: 8, volume: v };
        }
    }

    public play(): void {
        this.appService.showNotification("sending [play]");
        this.commands.push({ id: 1 });
    }

    public pause(): void {
        this.appService.showNotification("sending [pause]");
        this.commands.push({ id: 2 });
    }

    public nextTrack(): void {
        this.appService.showNotification("sending [next]");
        this.commands.push({ id: 3 });
        this.commands.push({ id: 99 });
    }

    public happyBirthday(): void {
        this.appService.showNotification("sending [birthday]");
        this.commands.push({ id: 6 });
    }

    public setShuffle(value: boolean): void {
        this.appService.showNotification("sending [shuffle]");
        this.commands.push({ id: 7, shuffle: value ? "on" : "off" });
        this.commands.push({ id: 99 }); // после некоторых команд добавлена 99-я, чтобы принудительно получить статус от сервера       
    }

    public seek(seekTime: number): void {
        this.appService.showNotification("sending [seek]");
        this.commands.push({ id: 9, sec: seekTime });
    }

    public search(s: string): void {
        this.appService.showNotification("sending [search]");
        this.commands.push({ id: 14, searchString: s });
        this.commands.push({ id: 99 });
    }

    public remove(songID: number): void {
        this.appService.showNotification("sending [remove]");
        this.commands.push({ id: 15, songID: songID });
        this.commands.push({ id: 99 });
    }

    public deleteForever(songID: number): void {
        this.appService.showNotification("sending [delete forever]");
        this.commands.push({ id: 27, songID: songID });
        this.commands.push({ id: 99 });
    }

    public playNext(songID: number): void {
        this.appService.showNotification("sending [play next]");
        this.commands.push({ id: 16, songID: songID });
        this.commands.push({ id: 99 });
    }

    public playNow(songID: number): void {
        this.appService.showNotification("sending [play now]");
        this.commands.push({ id: 17, songID: songID });
        this.commands.push({ id: 99 });
    }

    public setPlaylist(playlistID: number): void {
        this.appService.showNotification("sending [set playlist]");
        this.commands.push({ id: 26, playlistID: playlistID });
        this.commands.push({ id: 99 });
    }

    public addPlaylist(playlistID: number): void {
        this.appService.showNotification("sending [add playlist]");
        this.commands.push({ id: 35, playlistID: playlistID });
        this.commands.push({ id: 99 });
    }

    public getPlaylistSongs(playlistID: number): void {
        this.appService.showNotification("sending [get playlist songs]");
        this.commands.push({ id: 13, playlistID: playlistID });
    }

    public playlistSongPlayNow(playlistID: number, songID: number): void {
        this.appService.showNotification("sending [playlist song - play now]");
        this.commands.push({ id: 22, playlistID: playlistID, songID: songID });
        this.commands.push({ id: 99 });
    }

    public playlistSongPlayNext(playlistID: number, songID: number): void {
        this.appService.showNotification("sending [playlist song - play next]");
        this.commands.push({ id: 21, playlistID: playlistID, songID: songID });
        this.commands.push({ id: 99 });
    }

    // в поисковом списке свои идентификаторы, поэтому для манипуляций с песнями из списка найденных существуют отдельные функции
    public playNextAfterSearch(songID: number): void {
        this.appService.showNotification("sending [play next]");
        this.commands.push({ id: 23, songID: songID });
        this.commands.push({ id: 99 });
    }

    public playNowAfterSearch(songID: number): void {
        this.appService.showNotification("sending [play now]");
        this.commands.push({ id: 24, songID: songID });
        this.commands.push({ id: 99 });
    }

    // additional Newyear functions
    public ny1(): void {
        this.appService.showNotification("sending [ny1]");
        this.commands.push({ id: 28 });
        this.commands.push({ id: 99 });
    }

    public ny2(): void {
        this.appService.showNotification("sending [ny2]");
        this.commands.push({ id: 29 });
        this.commands.push({ id: 99 });
    }

    public UpdateLikeStatusOfPlaylist(playlistId: number, likeType: number): void {
        this.appService.showNotification("sending like status");
        this.commands.push({ id: 33, "playlistID": playlistId, "likeType": likeType });
        this.commands.push({ id: 99 });
    }

    public UpdateLikeStatusOfSong(page: string, playlistId: number, songId: number, typeLike: number) {
        this.appService.showNotification("sending like status");
        this.commands.push({ id: 32, page: page, playlistID: playlistId, songID: songId, likeType: typeLike });
        this.commands.push({ id: 99 });
    }

    public AllHeartsNextInMainPlaylist() {
        this.appService.showNotification("sending All hearts Next in the main PL");
        this.commands.push({ id: 34 });
        this.commands.push({ id: 99 });
    }

    public AllHeartsNextInSpecificPlaylist(playlistID: number) {
        this.appService.showNotification("sending All hearts Next from a specific PL");
        this.commands.push({ id: 30, playlistID: playlistID });
        this.commands.push({ id: 99 });
    }

    public AllHeartsNextInTheSearchResults() {
        this.appService.showNotification("sending All hearts Next from a specific PL");
        this.commands.push({ id: 31 });
        this.commands.push({ id: 99 });
    }


}
