import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServicesModule } from "./services/services.module";
import { AuthModule } from "./auth/auth.module";
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { AppErrorHandler } from './services/error-handler.service';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServicesModule,
    AuthModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    BackgroundMode,
    FirebaseX,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    MobileAccessibility
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
