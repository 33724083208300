import { ErrorHandler, Injectable } from '@angular/core';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from '@ionic/angular';
import * as stacktrace from "stacktrace-js";

@Injectable({providedIn: 'root'})
export class AppErrorHandler implements ErrorHandler {
 
  public Tag = "AppErrorHandler"
 
  constructor (
    private firebase: FirebaseX,
    private platform: Platform,
  ) {}
 
  handleError(error: any): void {
    console.error(error);
    if (this.platform.is("cordova")) {
      stacktrace.get().then(
        trace => this.firebase.logError(this.Tag + " | Error => " + error + " | Trace =>" + trace)
      );
    }
  }
}
