import { NgModule } from '@angular/core';
import { HttpModule } from "@angular/http";
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { CabinetComponent } from './cabinet.component';
import { AuthGuard } from '../services/auth.guard';

const routing = RouterModule.forChild(
    [
        { path: "login", component: LoginComponent, pathMatch: 'full' },
        { path: "cabinet", component: CabinetComponent, pathMatch: 'full', canActivate: [AuthGuard] },
        { path: "**", redirectTo: "login" }
    ]
);

@NgModule({
    declarations: [
        LoginComponent,
        CabinetComponent,
    ],
    exports: [
        LoginComponent,
        CabinetComponent,
    ],
    imports: [
        IonicModule,
        HttpModule,
        FormsModule,
        CommonModule,
        routing,
    ],
    providers: [
    ],
})
export class AuthModule { }