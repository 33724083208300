import { Component } from "@angular/core";
import { AlertController, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PlayerService } from '../services/player.service';
import { AppService } from '../services/app.service';
import { User } from '../model/user.model';

@Component({
    selector: 'auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './cabinet.component.scss'],
})
export class LoginComponent {
    public name: string = "";
    public password: string = "";
    public error: string = "";
    public children: User[] = [];

    constructor(
        private authService: AuthService,
        private playerService: PlayerService,
        private router: Router,
        private appService: AppService,
        private route: ActivatedRoute,
        private alertController: AlertController,
        private platform: Platform,
    ) {
        this.route.params.subscribe(p => {
            this.name = this.authService.prevUser.name;
            this.password = this.authService.prevUser.password;
        });
    }

    public login(isChildUser: boolean): void {
        this.name = this.name.trim().toLocaleLowerCase();
        this.password = this.password.trim().toLocaleLowerCase();

        const errorMsg = `האפליקצייה לא מצליחה להתחבר לנגן-מחשב, נא לבדוק:
            <ol>
                <li>שהקלדת את היוזר והסיסמה נכון.</li>
                <li>שהמחשב מחובר לאינטרנט - לוודא שכל הכבלים מחוברים כראוי לנגן-מחשב ולכבות ולהדליק אותו, ולחכות דקה לאחר מכן ולבדוק שוב אם התקלה נפתרה.</li>
            </ol>
            במידה והתקלה עדיין לא נפתרה - אנא צרו קשר עם שירות הלקוחות שלנו דרך הווטסאפ בלינק למטה.`;

        if (this.name.length && this.password.length) {
            this.appService.loading = "active";
            this.authService.authenticate(this.name, this.password).subscribe(res => {
                if (res.errorCode === "0") {
                    this.error = "";
                    this.authService.getChildUsers(this.name, this.password).subscribe(res2 => {
                        this.children = res2.filter(u => u.User !== this.name).map(u => new User(u.User, u.Password, u.NickName, u.Active)).sort();
                        this.authService.saveAuthData(this.name, this.password, this.children, isChildUser);

                        if (this.children.length > 0) {
                            this.router.navigateByUrl("/auth/cabinet");
                        }
                        if (!res2.find(x => x.User === this.name).Active) {
                            this.appService.loading = "";
                        } else {
                            this.playerService.start();
                            this.appService.loading = "";
                            this.router.navigateByUrl("/");
                        }
                    }, err => {
                        this.appService.loading = "";
                        this.error = `${errorMsg} | getChildUsers`;
                    });
                } else {
                    this.appService.loading = "";
                    this.error = errorMsg;
                }
            }, err => {
                this.appService.loading = "";
                this.error = `${errorMsg} | authenticate`;
            });
        }
    }

    public setCurrentUser(newUser: User) {
        if (newUser.name !== this.name && newUser.active) {
            this.name = newUser.name;
            this.password = newUser.password;
            this.login(true);
        }
    }

    public getUserClass(user: User): string {
        if (this.name === user.name) {
            return "active";
        }
        else if (!user.active) {
            return "disabled";
        }
        else {
            return "";
        }
    }

    public logout(): void {
        this.children = [];
    }

    // Just to check if mobile app was updated. Can delete later
    public async testPopup(): Promise<void> {
        const alert = await this.alertController.create({
            header: "Test popup",
            message: "3.10.29" + ` | android: ${this.platform.is("android")}, ios: ${this.platform.is("ios")}, cordova: ${this.platform.is("cordova")}`,
            buttons: ['OK'],
          });
        await alert.present();
    }
}
