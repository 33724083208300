import { ErrorCode } from "./errorcode";
import { ISong } from "./song.interface";
import { IPlaylist } from "./playlist.interface";
import { IPlaylistSongs } from './playlistsongs.interface';

export class State {
    constructor(
        public allTimeSong?: number,
        public cache_time?: number,
        public currentPlaylistName?: string,
        public currentSong?: ISong,
        public currentTimeSong?: number,
        public mainPlaylistSongs?: ISong[],
        public playlists?: IPlaylist[],
        public shuffle?: number,
        public volume?: number,
        public searchResultsList?: ISong[],
        public playlistSongs?: IPlaylistSongs[],

        public errorCode?: ErrorCode,
        public error?: string,
    ) {
    }

    public init(): State {
        this.allTimeSong = 0;
        this.cache_time = 0;
        this.currentPlaylistName = "";
        this.currentSong = { id: 0, name: "" };
        this.currentTimeSong = 0;
        this.mainPlaylistSongs = [];
        this.playlists = [];
        this.shuffle = 0;
        this.volume = 0;
        this.searchResultsList = [];
        this.playlistSongs = [];

        return this;
    }
}