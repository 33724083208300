export class User {
    constructor(
        public name?: string,
        public password?: string,
        public nick?: string,
        public active?: boolean,
        public children?: User[],
        public isChildUser?: boolean
    ) {
    }

    public init(): User {
        this.name = "";
        this.password = "";
        this.nick = "";
        this.active = false;
        this.children = [];
        this.isChildUser = false;

        return this;
    }
}