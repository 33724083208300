import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authService.authenticated) {
            this.router.navigateByUrl("/auth/login");
            return false;
        }
        else {
            return true;
        }
    }
}