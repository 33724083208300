import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { ApiService } from "./api.service";
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { PlayerService } from './player.service';
import { AppService } from './app.service';

@NgModule({
    declarations: [
    ],
    exports: [
    ],
    imports: [
        HttpClientModule,
        HttpClientJsonpModule,
    ],
    providers: [
        ApiService,
        AuthService,
        AuthGuard,
        PlayerService,
        AppService,
    ],
})
export class ServicesModule { }