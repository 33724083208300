import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {path: "", redirectTo: "tab1", pathMatch: "full"},
  {path: "tab1", loadChildren: () => import ("./tab1/tab1.module").then (m => m.Tab1PageModule), canActivate: [AuthGuard]},
  {path: "tab2", loadChildren: () => import ("./tab2/tab2.module").then (m => m.Tab2PageModule), canActivate: [AuthGuard]},
  {path: "tab3", loadChildren: () => import ("./tab3/tab3.module").then (m => m.Tab3PageModule), canActivate: [AuthGuard]},
  {path: "tab4", loadChildren: () => import ("./tab4/tab4.module").then (m => m.Tab4PageModule)},  
  {path: "auth", loadChildren: () => import ("./auth/auth.module").then (m => m.AuthModule)},  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
