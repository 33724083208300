import { Injectable } from "@angular/core";
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

import { IAuthResponse, IChildrenResponse } from "../model/auth.response.interface";
import { User } from "../model/user.model";

@Injectable()
export class AuthService {
    public authenticated: boolean = false;
    public user: User = (new User()).init();
    public currentUser: User = (new User()).init();
    public prevUser: User = (new User()).init();

    constructor(
        private apiService: ApiService,
    ) {
        if (localStorage.getItem("user") && localStorage.getItem("currentuser")) {
            this.user = JSON.parse(localStorage.getItem("user"));
            this.currentUser = JSON.parse(localStorage.getItem("currentuser"));
            this.authenticated = true;
        }

        if (localStorage.getItem("prevuser")) {
            this.prevUser = JSON.parse(localStorage.getItem("prevuser"));
        }
    }

    public authenticate(name: string, password: string): Observable<IAuthResponse> {
        return this.apiService.authenticate(name, password);
    }

    public getChildUsers(name: string, password: string): Observable<IChildrenResponse[]> {
        return this.apiService.getChildUsers(name, password);
    }

    public saveAuthData(name: string, password: string, children: User[], isChildUser: boolean): void {
        if (!isChildUser) {
            this.user.name = name;
            this.user.password = password;
            this.user.children = children;
            this.user.active = true;
            localStorage.setItem("user", JSON.stringify(this.user));

            for (const field in this.user) {
                this.currentUser[field] = this.user[field];
            }

            localStorage.setItem("currentuser", JSON.stringify(this.currentUser));
        }
        else {
            this.currentUser.name = name;
            this.currentUser.password = password;
            this.currentUser.isChildUser = true;
            localStorage.setItem("currentuser", JSON.stringify(this.currentUser));
        }

        this.authenticated = true;
    }

    public logout(): void {
        this.prevUser = this.currentUser.isChildUser ? this.user : this.currentUser;
        localStorage.setItem("prevuser", JSON.stringify(this.prevUser));
        this.user = (new User()).init();
        this.currentUser = (new User()).init();
        localStorage.removeItem("user");
        localStorage.removeItem("currentuser");
        this.authenticated = false;
    }

    public setCurrentUser(user: User): void {
        this.currentUser = user;
        localStorage.setItem("currentuser", JSON.stringify(this.currentUser));
    }
}