import { Component, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PlayerService } from './services/player.service';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { DOCUMENT } from '@angular/common';
import { EventEmitter } from 'events';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { User } from './model/user.model';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	public nyActive: boolean = false;

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private playerService: PlayerService,
		private appService: AppService,
		private authService: AuthService,
		private backgroundMode: BackgroundMode,
		private mobileAccessibility: MobileAccessibility,
		@Inject(DOCUMENT) document,
	) {
		this.initializeApp();
	}

	get loading(): string { return this.appService.loading; }
	get notification(): string { return this.appService.notification; }
	get notificationClass(): string { return this.appService.notificationClass; }
	get authenticated(): boolean { return this.authService.authenticated; }
	get currentUser(): User { return this.authService.currentUser; }
	get countOfBoldPlaylists(): number { return this.playerService.state.playlists.filter(x => x.bold).length; }

	private initializeApp(): void {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();

			// Enable Background
			this.backgroundMode.enable();

			var platformWidth: number = this.platform.width();
			if (platformWidth <= 400) {
				this.mobileAccessibility.usePreferredTextZoom(false);
				this.mobileAccessibility.setTextZoom(100);
			}

			this.platform.pause.subscribe(() => {
				this.playerService.platformPauseSubject.next(true);
	 		});

			this.platform.resume.subscribe(() => {
				this.playerService.platformPauseSubject.next(false);
	 		});
		});
	}

	public ny1(event: MouseEvent): void {
		event.stopPropagation();
		this.playerService.ny1();
		this.nyActive = false;
	}

	public ny2(event: MouseEvent): void {
		event.stopPropagation();

		if (confirm("Are you sure?")) {
			this.playerService.ny2();
			this.nyActive = false;
		}
	}

	public onMainWindowClick(event: MouseEvent): void {
		this.nyActive = false;
	}

	public onNyPanelClick(event: MouseEvent): void {
		event.stopPropagation();
	}

	public toggleNyPanel(event: MouseEvent): void {
		event.stopPropagation();
		this.nyActive = !this.nyActive;
	}

	public playingNowClick(event: MouseEvent): void {
		if (document.URL.includes("tab1")) {
			let re: HTMLElement = document.getElementsByClassName('playlist-open')[0] as HTMLElement;
			if (re !== null) {
				re.click();
			}
		}
	}

	public playlistsClick(event: EventEmitter): void {
		let re: HTMLElement = document.getElementById('playlistsongs-close') as HTMLElement;
		if (re != null) {
			re.click();
		}
	}
}
