import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppService
{
    public loading: string = "";
    public notification: string = "test";
    public notificationClass: string = "";
    public notificationTimer: any = null;     

    public showNotification (s: string): void 
    {
        if (this.notificationTimer)
        {
            clearTimeout (this.notificationTimer);
        }

        this.notification = s;
        this.notificationClass = "active";
        this.notificationTimer = setTimeout (
            () =>
            {
                this.notificationClass = "";
                this.notificationTimer = null;
            }, 2000
        );
    }
}