import { Component } from "@angular/core";
import { AuthService } from '../services/auth.service';
import { User } from '../model/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PlayerService } from '../services/player.service';

@Component({
    selector: 'cabinet',
    templateUrl: './cabinet.component.html',
    styleUrls: ['./cabinet.component.scss'],
})
export class CabinetComponent {
    constructor(
        private authService: AuthService,
        private playerService: PlayerService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.route.params.subscribe(p => {
            // if this is not multiuser or not authenticated then logout
            if (!this.user.children.length) {
                this.logout ();
            }
        });
    }

    get user(): User { return this.authService.user; }
    get currentUser(): User { return this.authService.currentUser; }

    public logout(): void {
        this.playerService.stop();
        this.authService.logout();
        this.router.navigateByUrl("/auth/login");
    }

    public setCurrentUser(newUser: User) {
        if (newUser.name !== this.currentUser.name && newUser.active) {
            this.playerService.stop();
            newUser.isChildUser = true;
            this.authService.setCurrentUser(newUser);
            this.playerService.start();
            this.router.navigateByUrl("/tab1");
        }
    }

    public getUserClass(user: User): string {
        if (this.currentUser.name === user.name) {
            return "active";
        }
        else if (!user.active) {
            return "disabled";
        }
        else {
            return "";
        }
    }
}